import React from 'react'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import SEO from '../components/seo'

import Layout from '../components/layout'
import Carousel from '../components/carousel'
import BadgeGrid from '../components/badge-grid'
// import EmailSignup from '../components/email-signup'
import Grid from '../components/grid'
import Button from '../components/button'

const HR = styled.hr`
  margin: 72px auto;
  opacity: 0.3;
`

const Title = styled.h1`
  display: block;
  margin: 106px auto 60px auto;
  text-align: center;
  font-size: 4em;
  line-height: normal;

  @media only screen and (max-width: 420px) {
    font-size: 3em;
    margin-bottom: 32px;
  }
`

const CenterDiv = styled.div`
  margin: 96px auto 48px auto;
  text-align: center;
`

const ServiceButton = styled(Button)`
  padding: 14px 40px;
`

const IndexPage = () => (
  // <Layout>

  // </Layout>
  <StaticQuery
    query={graphql`
      {
        home: contentfulHomepage(
          id: { eq: "3a760dd9-ff49-5fea-90f4-2fd2543c7f2e" }
        ) {
          title
          badges {
            json
          }
          slideshow {
            title
            description
            fluid(maxHeight: 1200) {
              src
              aspectRatio
              srcSet
            }
          }
          centreImage {
            fluid {
              src
              aspectRatio
              srcSet
            }
          }
          banner
          grunde {
            json
          }
        }
      }
    `}
    render={props => (
      <div>
        <SEO
          title="Home"
          keywords={[
            'bodytunes',
            'ems',
            'ems-training',
            'ems-studio',
            'fitness'
          ]}
        />
        <Carousel images={props.home.slideshow} />

        <Layout>
          <BadgeGrid pageContext={props.home.badges} />

          <HR />

          {/* <EmailSignup /> */}

          <Title>{props.home.banner}</Title>

          <Grid pageContext={props.home} />

          <CenterDiv>
            <Link to="/leistungen">
              <ServiceButton>LEISTUNGEN</ServiceButton>
            </Link>
          </CenterDiv>
        </Layout>
      </div>
    )}
  />
)

export default IndexPage
