import React from 'react'
import styled from 'styled-components'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'

import downArrowIcon from '../images/round-keyboard_arrow_down-24px.svg'
import BookIcon from '../images/book-open-variant.svg'

const BadgeGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto -12px;
`

const Badge = styled.div`
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 21%;
  margin: 0 12px 24px 12px;
  height: 240px;
  min-width: 130px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 1.5em;
  color: var(--heading-text);
  transition: background 210ms;

  :hover {
    background: var(--deep-red);

    h3 {
      color: white;
    }
  }

  ::after {
    content: url(downArrow);
    background: #f3f3f3;
    position: absolute;
    border-radius: 50%;
    bottom: -30px;
    left: 37%;
    width: 26%;
    height: 55px;
  }

  @media only screen and (max-width: 768px) {
    flex: 1 1 42%;
  }
`

const Book = styled.div`
  position: absolute;
  top: 26%;
  opacity: 0.08;
`

const DownArrow = styled.div`
  position: absolute;
  bottom: -3px;
  opacity: 0.6;
  z-index: 100;
`

const Heading = styled.h3`
  font-weight: bold;
  transition: color 210ms;
`

const Tagline = styled.p`
  font-size: 0.5em;
  color: var(--heading-text);
`

const PlainLink = styled(Link)`
  display: contents;
  text-decoration: none;
`

const OPTIONS = {
  renderMark: {},
  renderNode: {
    [BLOCKS.PARAGRAPH]: children => <p>{children}</p>,
    [INLINES.EMBEDDED_ENTRY]: node => (
      <PlainLink
        to={node.data.target.fields.pageLink['en-US'].fields.slug['en-US']}
      >
        <Badge>
          <Heading>
            {node.data.target.fields.heading['en-US'].toUpperCase()}
          </Heading>
          <Tagline>{node.data.target.fields.tagline['en-US']}</Tagline>
          <Book>
            <img src={BookIcon} alt="Book icon" />
          </Book>
          <DownArrow>
            <img src={downArrowIcon} alt="Down arrow icon" />
          </DownArrow>
        </Badge>
      </PlainLink>
    )
  }
}

function Badges ({ pageContext }) {
  return (
    <BadgeGrid>
      {documentToReactComponents(pageContext.json.content[0], OPTIONS)}
    </BadgeGrid>
  )
}

export default Badges
