import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import Badge from './probe-badge'

const Wrapper = styled.div`
  .anim-zoom {
    animation: anim-zoom linear 7500ms;
  }

  .anim-heading {
    animation: anim-heading linear 7500ms;
  }

  .anim-tagline {
    animation: anim-tagline linear 7500ms;
  }

  .anim-badge {
    animation: anim-badge linear 7500ms;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 124px;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2em;

  @keyframes anim-zoom {
    0% {
      transform: scale(1);
      opacity: 0.7;
    }
    7% {
      opacity: 1;
    }
    93% {
      opacity: 1;
    }
    100% {
      transform: scale(1.12);
      opacity: 0.5;
    }
  }

  @keyframes anim-heading {
    10% {
      opacity: 0;
    }
    23% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes anim-tagline {
    30% {
      opacity: 0;
    }
    45% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes anim-badge {
    55% {
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`

const Image = styled(Img)`
  position: relative;
  height: 0;
  padding-bottom ${0.666 * 75}%;

  picture img {
    object-position: center 30% !important;
  }
`

const FlexWrap = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    padding-top: 48px;
    font-size: 1em;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 0.84rem;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 0;
    font-size: 0.7rem;
  }
  @media only screen and (max-width: 448px) {
    font-size: 0.5rem;
  }
`

const Header = styled.h1`
  opacity: 0;
  font-size: 3em;
  color: ${props => (props.color === 'slide2' ? 'var(--deep-red)' : 'white')};
`

const Tagline = styled.p`
  opacity: 0;
  text-transform: uppercase;
  color: ${props => (props.color === 'slide2' ? 'var(--deep-red)' : 'white')};

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function Carousel (props) {
  const startAnimation = (index, latest) => {
    // Add to current slide
    document
      .getElementById('carousel')
      .children[0].children[index].children[0].children[0].classList.add(
        'anim-zoom'
      )

    document
      .getElementById('carousel')
      .children[0].children[
        index
      ].children[0].children[1].children[0].classList.add('anim-heading')

    document
      .getElementById('carousel')
      .children[0].children[
        index
      ].children[0].children[1].children[1].classList.add('anim-tagline')

    document
      .getElementById('carousel')
      .children[0].children[
        index
      ].children[0].children[1].children[2].classList.add('anim-badge')

    // Remove from previous

    document
      .getElementById('carousel')
      .children[0].children[latest].children[0].children[0].classList.remove(
        'anim-zoom'
      )

    document
      .getElementById('carousel')
      .children[0].children[
        latest
      ].children[0].children[1].children[0].classList.remove('anim-heading')

    document
      .getElementById('carousel')
      .children[0].children[
        latest
      ].children[0].children[1].children[1].classList.remove('anim-tagline')

    document
      .getElementById('carousel')
      .children[0].children[
        latest
      ].children[0].children[1].children[2].classList.remove('anim-badge')
  }

  return (
    <Wrapper>
      <AutoPlaySwipeableViews
        id="carousel"
        onChangeIndex={startAnimation}
        interval={7500}
        springConfig={{}}
        slideStyle={{ overflow: 'hidden' }}
        disabled
      >
        {props.images.map((image, index) => (
          <Flex>
            <Image
              fluid={image.fluid}
              alt={image.description}
              className={index === 0 ? 'anim-zoom' : null}
            />
            <FlexWrap>
              <Header
                color={image.title}
                className={index === 0 ? 'anim-heading' : null}
              >
                {image.description.split('-')[0]}
              </Header>
              <Tagline
                color={image.title}
                className={index === 0 ? 'anim-tagline' : null}
              >
                {image.description.split('-')[1]}
              </Tagline>
              <Badge
                color={image.title}
                className={index === 0 ? 'anim-badge' : null}
              />
            </FlexWrap>
          </Flex>
        ))}
      </AutoPlaySwipeableViews>
    </Wrapper>
  )
}

export default Carousel
