import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import downArrowIcon from '../images/round-keyboard_arrow_down-24px-white.svg'

const Ring = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  width: 9vw;
  height: 9vw;
  border: thin solid
    ${props =>
    (props.color === 'slide2' ? 'var(--deep-red)' : 'rgba(255,255,255,0.45)')};
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  opacity: 0;
  transition: border-color 280ms;

  :hover,
  :hover::after {
    border-color: var(--deep-red);
  }

  div {
    position: relative;
  }

  h6 {
    letter-spacing: 0.06em;
    font-size: 14.5px;
    font-weight: bold;
    color: ${props => (props.color === 'slide2' ? 'var(--deep-red)' : 'white')};

    @media only screen and (max-width: 1200px) {
      font-size: 12.5px;
    }
  }

  img {
    position: absolute;
    width: 32%;
    left: 36%;
  }

  ::after {
    content: "";
    position: absolute;
    top: -1.13vw;
    width: 11vw;
    height: 11vw;
    border: thin solid
      ${props =>
    (props.color === 'slide2'
      ? 'var(--deep-red)'
      : 'rgba(255,255,255,0.45)')};
    border-radius: 50%;
    transition: border-color 280ms;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

function Badge (props) {
  return (
    <Ring color={props.color} to="kontakt" className={props.className}>
      <div>
        <h6>
          PROBE-
          <br />
          TRAINING
        </h6>
        <img src={downArrowIcon} alt="Down arrow icon" />
      </div>
    </Ring>
  )
}

export default Badge
