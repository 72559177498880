import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

const GridItem = styled(Grid)`
  flex: ${props => props.flex};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    order: ${props => (props.left ? 1 : props.right ? 2 : 0)};
  }
`

const HeadingLink = styled.a`
  position: relative;
  display: inline-block;
  margin: 18px auto;
  text-decoration: none;
  }
`

const Excerpt = styled.p`
  font-size: 0.75rem;
  line-height: 1.3rem;
  color: var(--main-text);
`

const Wrapper = styled.div`
  position: relative;
  text-align: ${props => props.side};
`

const GridImage = styled(GridItem)`
  flex: 1.8;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex: auto;
  }
`

const CenterImage = styled(Img)`
  width: 100%;
`

const Number = styled.h6`
  position: absolute;
  top: 15px;
  left: ${props => (props.left ? '-2px' : 'initial')};
  right: ${props => (props.right ? '-2px' : 'initial')};
  font-size: 4em;
  color: var(--main-text);
  opacity: 0.2;
`

function StyledGrid ({ pageContext }) {
  return (
    <Grid container spacing="24">
      <GridItem left item flex="1">
        {pageContext.grunde.json.content
          .filter(e => e.nodeType === 'embedded-entry-block')
          .splice(0, 2)
          .map((node, index) => (
            <Wrapper side="left">
              <Number left>{(index + 1).toString().padStart(2, '0')}</Number>
              <HeadingLink
                href={
                  node.data.target.fields.heading[
                    'en-US'
                  ].content[0].content.filter(
                    e => e.nodeType === 'hyperlink'
                  )[0].data.uri
                }
              >
                <h3>
                  {node.data.target.fields.heading['en-US'].content[0].content
                    .filter(e => e.nodeType === 'hyperlink')[0]
                    .content[0].value.toUpperCase()}
                </h3>
              </HeadingLink>
              <Excerpt>{node.data.target.fields.excerpt['en-US']}</Excerpt>
            </Wrapper>
          ))}
      </GridItem>

      <GridImage item flex="1.8" display="flex" justify="center">
        <CenterImage
          fluid={pageContext.centreImage.fluid}
          alt="{pageContext.centreImage.description}"
        />
      </GridImage>

      <GridItem right item flex="1">
        {pageContext.grunde.json.content
          .filter(e => e.nodeType === 'embedded-entry-block')
          .splice(2)
          .map((node, index) => (
            <Wrapper side="right">
              <Number right>{(index + 3).toString().padStart(2, '0')}</Number>
              <HeadingLink
                href={
                  node.data.target.fields.heading[
                    'en-US'
                  ].content[0].content.filter(
                    e => e.nodeType === 'hyperlink'
                  )[0].data.uri
                }
              >
                <h3>
                  {node.data.target.fields.heading['en-US'].content[0].content
                    .filter(e => e.nodeType === 'hyperlink')[0]
                    .content[0].value.toUpperCase()}
                </h3>
              </HeadingLink>
              <Excerpt>{node.data.target.fields.excerpt['en-US']}</Excerpt>
            </Wrapper>
          ))}
      </GridItem>
    </Grid>
  )
}

export default StyledGrid
